import React from 'react';
import { Helmet } from 'react-helmet'


import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import IntroBg from "../../assets/images/homeIntroBg.jpeg";
import homeHeaderPolygonLeft from "./assets/images/homeHeaderPolygonLeft.svg";
import homeHeaderPolygonRight from "./assets/images/homeHeaderPolygonRight.svg";
import './assets/css/index.css';


import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import Ticket from "../../blocks/ticket/Ticket";

import HeaderVideo from "../../assets/videos/headerVideo2024.mp4";

import HomeCountdown from "./HomeCountdown";
import HomeCovid from "./HomeCovid";
import HomeIntroHeadline from "./HomeIntroHeadline";
import HomeScrollButton from "./HomeScrollButton";
import HomeLineup from "./HomeLineup";

import ScrollAnimation from 'react-animate-on-scroll';
import PagePreloader from "../../helpers/PagePreloader";

import { withTranslation, Trans } from 'react-i18next';
import Marquee from "react-fast-marquee";


import HomeIntroTextLeftPolygon from "./assets/images/homeIntroTextLeftPolygon.svg";
import HomeIntroTextRightPolygon from "./assets/images/homeIntroTextRightPolygon.svg";


import EarlyBirdTicket from "./assets/images/earlyBirdTicket.png";

class Home extends React.Component {

  constructor(props) {
    super(props);

    ////console.log(props);

    this.state = {
      loading: true,
    };

    this.homeWelcomeRef = React.createRef();

  }


  componentDidMount() {
    this.setState({ loading: false });
    document.body.classList.add('home');
  }

  componentWillUnmount() {
    document.body.classList.remove('home');
  }




  render() {


    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="home">

          <div className="intro-bar">

            <div className="video-bar">
              <video playsInline autoPlay muted loop preload="metadata" className="H_i_h" data-automation="VideoPlayer_video_video">
                <source src={HeaderVideo} type="video/webm" />
                <source src={HeaderVideo} type="video/mp4" />
                <source src={HeaderVideo} type="video/ogg" />
              </video>
            </div>


            <div className="stroke-headline-bar-01">
              <HomeIntroHeadline text="We are one." delay="1500" />
            </div>

            <div className="stroke-headline-bar-02">
              <HomeIntroHeadline text="We are alba." delay="2500" />
            </div>


            {/*
              <HomeCovid />*/}

            <HomeCountdown />

            <div className="arrow-bar">
              <HomeScrollButton scrollTo={this.homeWelcomeRef} />
            </div>


            <div className="bg-bar">
              <img src={IntroBg} />
            </div>


            <div className="triangle-bar">
              <div className="animatesTriangleLeft">
                <img src={homeHeaderPolygonLeft} />
              </div>

              <div className="animatesTriangleRight">
                <img src={homeHeaderPolygonRight} />
              </div>
            </div>


            {/*

              <div className="triangle-bar">
                <div className="animatesTriangleLeft">
                  <TriangleAnimated />
                </div>

                <div className="animatesTriangleRight">
                  <TriangleAnimated />
                </div>
              </div>
              */}

          </div>

          <div className="home-ticket-bar">
            <Ticket />
          </div>



          <div className="home-welcome-bar" ref={this.homeWelcomeRef}>


            <div className="container-fluid">
              <h1>{this.props.t('alba Festival 2025')}</h1>
              <h2>{this.props.t('Kasernenareal, Zürich')}</h2>
              <div className="body">

                {this.props.t('Entdeckt die albanische Lebensfreude und erlebt die Vielfalt albanischer Musik. Am alba Festival, dem grössten und stimmungsvollsten Festival für albanische Musik und Kultur in Europa. Hier spielen die Stars der albanischen Musikszene aus der ganzen Welt.')}<br /><br />

                {this.props.t('Auf dem Kasernenareal, im Herzen der Stadt Zürich, nahe des Hauptbahnhof. Am 21. - 22. Juni 2025.')}<br /><br />

                {this.props.t('Sichere dir jetzt deine Tickets und folge uns auf unseren sozialen Kanälen. So entgehen dir keine News zum diesjährigen Lineup und zu den diesjährigen Attraktionen.')}
              </div>




              <div>
                <img src={EarlyBirdTicket} />
              </div>

              <a style={{marginBottom: '2rem'}} href="https://www.ticketmaster.ch/event/alba-festival-2025-2-tages-pass-21-22-06-2025-tickets/33560657" target="_blank" className="btn btn-primary btn-ticket">
                <FontAwesomeIcon icon={AllLightIcons.faTicket} />

                {this.props.t('Jetzt Tickets sichern')}

              </a>


              <h1 style={{color: '#e20917'}}>{this.props.t('EARLY BIRD 2-TAGES-TICKET')}</h1>
              <div className="body">

                {this.props.t('Sichere dir dein exklusives Early-Bird-Ticket für ein unvergessliches Festivalerlebnis!')}

                {this.props.t('Mit deinem Early-Bird-Ticket geniesst du an beiden Festivaltagen Zutritt zum alba Festivalgelände:')}<br />

                <strong style={{color: '#e20917', fontWeight: 'bold'}}>{this.props.t('Dieses Ticket ist streng limitiert und nur für kurze Zeit verfügbar – greif jetzt zu, solange der Vorrat reicht, und werde Teil dieses aussergewöhnlichen Events!')}</strong>
              </div>


              <div className="icon-bar">
                <FontAwesomeIcon icon={AllLightIcons.faChevronDoubleDown} />
              </div>


            </div>


            <div className="animatesTriangleLeft">
              <img src={HomeIntroTextLeftPolygon} />
            </div>

            <div className="animatesTriangleRight">
              <img src={HomeIntroTextRightPolygon} />
            </div>
          </div>





        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Home);
